import React, { useState } from 'react';

const Dice = () => {
  const [isRolling, setIsRolling] = useState(false);
  const [result1, setResult1] = useState(1);
  const [result2, setResult2] = useState(1);
  const [style1, setStyle1] = useState({});
  const [style2, setStyle2] = useState({});

  const generateRandomFace = () => {
    return Math.floor(Math.random() * 6) + 1;
  };

  const getRotationStyles = (face) => {
    switch (face) {
      case 1:
        return 'rotateX(0deg) rotateY(0deg)';
      case 2:
        return 'rotateX(-90deg) rotateY(0deg)';
      case 3:
        return 'rotateX(0deg) rotateY(90deg)';
      case 4:
        return 'rotateX(0deg) rotateY(-90deg)';
      case 5:
        return 'rotateX(90deg) rotateY(0deg)';
      case 6:
        return 'rotateX(180deg) rotateY(0deg)';
      default:
        return 'rotateX(0deg) rotateY(0deg)';
    }
  };

  const toggleKeyframe = () => {
    const randomFace1 = generateRandomFace();
    const randomFace2 = generateRandomFace();
    setIsRolling(true);

    const interval = setInterval(() => {
      setStyle1({ transform: `rotateX(${Math.random() * 360}deg) rotateY(${Math.random() * 360}deg)` });
      setStyle2({ transform: `rotateX(${Math.random() * 360}deg) rotateY(${Math.random() * 360}deg)` });
    }, 100);

    setTimeout(() => {
      clearInterval(interval);
      setResult1(randomFace1);
      setResult2(randomFace2);
      setStyle1({ transform: getRotationStyles(randomFace1) });
      setStyle2({ transform: getRotationStyles(randomFace2) });
      setIsRolling(false);
    }, 0);
  };

  return (
    <>
      <main>
        <div className="dice-wrapper">
          <div className={`dice-container ${isRolling ? 'rolling' : ''}`}>
            <div className="dice" style={style1}>
              <div className="front">
                <span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <div className="back">
                <span></span>
              </div>
              <div className="right">
                <span></span><span></span><span></span><span></span><span></span>
              </div>
              <div className="left">
                <span></span><span></span>
              </div>
              <div className="top">
                <span></span><span></span><span></span>
              </div>
              <div className="bottom">
                <span></span><span></span><span></span><span></span>
              </div>
            </div>
          </div>
          <div className={`dice-container ${isRolling ? 'rolling' : ''}`}>
            <div className="dice" style={style2}>
              <div className="front">
                <span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <div className="back">
                <span></span>
              </div>
              <div className="right">
                <span></span><span></span><span></span><span></span><span></span>
              </div>
              <div className="left">
                <span></span><span></span>
              </div>
              <div className="top">
                <span></span><span></span><span></span>
              </div>
              <div className="bottom">
                <span></span><span></span><span></span><span></span>
              </div>
            </div>
          </div>
        </div>
        <div className="button" onClick={toggleKeyframe}>
          <span>Lancer les dés</span>
        </div>
      </main>
      <div className='stuck-step'>
        Vous êtes bloqués ? rejoignez-nous sur <a className='link' href="https://www.instagram.com/talesandkeys/">Instagram</a> et posez votre question !
      </div>
    </>
  );
};

export default Dice;
