import React, { useState, useEffect } from 'react';

export default function HomepageMainSection() {
  const [text, setText] = useState('');
  const [isTextVisible, setIsTextVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsTextVisible(true), 2000);
  }, []);

  useEffect(() => {
    const paragraph =
      `À la fin des années 1980, l'opérateur Juliet// de l'époque menait des recherches à Lille à propos des signes alchimiques inscrits sur les portes de certains riverains. Sans en mesurer l'ampleur à ce moment-là, lors de la fameuse “Braderie”, elle fit l'acquisition d'un étrange manuscrit nébuleux, un document d'une importance capitale, qui demeura enfoui dans nos archives pendant près de trois décennies. C'est en fouillant nos dossiers dans le cadre de l'affaire du Démon de la Citadelle : Isla Obscura, que nous avons redécouvert ce précieux texte (voir annexes : lettre).

      Opérateur, nous soupçonnons une perturbation électrique mystérieuse du côté de Lens, dans le Pas-de-Calais. Selon nos renseignements, une Intelligence Artificielle qui aurait dû être hors service semble manifester une activité anormale. Vous êtes chargé d'étudier ce phénomène sur place et de rapporter toutes les informations possibles. Une première piste intrigante concerne le logo d'Autour du Louvre-Lens, qui selon Echo// ne représenterait pas une pyramide noire, mais... une patte de mouton ? Non, mais c'est une plaisanterie ?


      Foxtrott//
      `;

    let index = 0;
    const intervalId = setInterval(() => {
      if (isTextVisible) {
        setText(paragraph.slice(0, index));
        index++;
      }
      if (index > paragraph.length) {
        clearInterval(intervalId);
      }
    }, 30);

    return () => clearInterval(intervalId);
  }, [isTextVisible]);

  return (
    <>
      <div className="main">
        {isTextVisible && <p className="main__paragraph">{text}</p>}
      </div>
    </>
  );
}
