import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import Header from '../Components/Header';
import MainSection from '../Components/HomepageMainSection';
import Navbar from '../Components/Navbar';
import ReactGA from 'react-ga4';

export default function Homepage() {
  const navigate = useNavigate();
  const [showTitle, setShowTitle] = useState(false);
  const [showFirstContainer, setShowFirstContainer] = useState(false);
  const [showSecondContainer, setShowSecondContainer] = useState(false);
  const [showFirstDiv, setShowFirstDiv] = useState(false);
  const [showSecondDiv, setShowSecondDiv] = useState(false);

  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-GBDKKHN7RW');
    ReactGA.send('pageview', { page_path: window.location.pathname });
  }, [location]);

  useEffect(() => {
    setTimeout(() => setShowTitle(true), 500);
    setTimeout(() => setShowFirstDiv(true), 1000);
    setTimeout(() => setShowSecondDiv(true), 1500);
  }, []);

  const firstInvestigation = () => {
    navigate('/01');
  };

  useEffect(() => {
    // afficher le titre en premier
    setShowTitle(true);
  }, []);

  useEffect(() => {
    // afficher la première div après l'affichage du titre
    if (showTitle) {
      setShowFirstContainer(true);
    }
  }, [showTitle]);

  useEffect(() => {
    // afficher la deuxième div après l'affichage de la première
    if (showFirstContainer) {
      setShowSecondContainer(true);
    }
  }, [showFirstContainer, showSecondContainer]);

  return (
    <>
    <Helmet>
      <title>Accueil</title>
    </Helmet>
    <Navbar />
    <Header />
    <div className="homePageContainer">
      {showTitle && <h2 className="homePageContainer__title animate-opacity">Ou commencez par un parcours</h2>}
      <div className='homePageContainer__headerContainer'>
        {showFirstDiv && (
          <div onClick={firstInvestigation} className='homePageContainer__headerContainer__container animate-opacity'>
            <h2 className='homePageContainer__headerContainer__container__title'>Court Circuit</h2>
            <FontAwesomeIcon icon={faPlay} className="homePageContainer__headerContainer__container__icone" />
          </div>
        )}
      </div>
    </div>
    <MainSection />
  </>

  );
}
