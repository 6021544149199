import React, { useState } from 'react';
import { BrowserRouter as RouterContainer, Routes, Route } from 'react-router-dom';
import { toast, ToastContainer, Slide } from 'react-toastify';
import { HiddenEasterEgg } from 'react-hidden-easter-egg';
import { ConfettiCanvas } from 'react-raining-confetti';

import Homepage from './Pages/HomePage';
//import Post from './Components/Post';
import PostDetails from './Components/PostDetails';
//import Lieu from './Components/Lieu';
import LieuDetails from './Components/LieuDetails';
import Annexes from './Components/Annexes';
import LoginPage from './Pages/LoginPage';
import DashboardPage from './Pages/DashboardPage';
import ProfilePage from './Pages/ProfilePage';
import Indexpage from './Pages/Indexpage';

function Router() {
  const [isLogged, setIsLogged] = useState(Boolean(sessionStorage.getItem('accessToken')) || false);
  const [accessToken, setAccessToken] = useState(sessionStorage.getItem('accessToken') || '');

  const notify = (text, type) => {
    console.log("notify called")
    toast(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
      type: type || "info",
      transition: Slide
    })
  };

  return (
    <RouterContainer>
      <HiddenEasterEgg code={['e', 'p', 's', 'i',]} resetEggMs={10000} cb={() => console.log('start raining')}>
        <ConfettiCanvas active={true} fadingMode="LIGHT" stopAfterMs={5000} />
      </HiddenEasterEgg>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Homepage />} />
          {
            isLogged ?
              <>
                <Route path="/dashboard" element={<DashboardPage notify={notify} accessToken={accessToken} setAccessToken={setAccessToken} setIsLogged={setIsLogged} />} />
                <Route path="/profil" element={<ProfilePage notify={notify} accessToken={accessToken} setAccessToken={setAccessToken} setIsLogged={setIsLogged} />} />
                <Route path="/login" element={<LoginPage notify={notify} setAccessToken={setAccessToken} setIsLogged={setIsLogged} />} />

              </>
              :
              <>
                <Route path="/login" element={<LoginPage notify={notify} setAccessToken={setAccessToken} setIsLogged={setIsLogged} />} />
                <Route path="/dashboard" element={<DashboardPage notify={notify} accessToken={accessToken} setAccessToken={setAccessToken} setIsLogged={setIsLogged} />} />
              </>
          }
          <Route path="/annexes" element={<Annexes />} />
          <Route path="/index" element={<Indexpage />} />
          <Route path='/:code' element={<PostDetails />} />
          <Route path='/lieu/:title' element={<LieuDetails />} />
        </Routes>
    </RouterContainer>
  )
}

export default Router;
