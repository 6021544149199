import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import API from "../API";
import Navbar from "./Navbar";
import Header from "./Header";
import Loading from "./Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import { faLocationDot, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { ConfettiCanvas } from "react-raining-confetti";

export default function PostDetail({ posts }) {
  const [post, setPost] = useState({});
  const [lieux, setLieux] = useState();
  const [enigmes, setEnigmes] = useState();
  const [images, setImages] = useState();
  const { serverUrl } = useContext(API);
  const [selectedLieuId, setSelectedLieuId] = useState(null);
  const [isFlipped, setIsFlipped] = useState(false);
  const [showEnigmeText, setShowEnigmeText] = useState(false);
  const [userReponse, setUserReponse] = useState("");
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [showLieuDesc, setShowLieuDesc] = useState(false);
  const [FontAwesome] = useState(FontAwesomeIcon);
  const [prevPost, setPrevPost] = useState(null);
  const [nextPost, setNextPost] = useState(null);
  const [doublenext, setDoublenext] = useState(null); // Ajout de l'état doublenext
  const [errorCount, setErrorCount] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [selectedLieuImage, setSelectedLieuImage] = useState([]);

  const location = useLocation();

  const titleRef = useRef(null);
  const [isTitleTooLong, setIsTitleTooLong] = useState(false);

  useEffect(() => {
    const handleTitleChange = () => {
      if (titleRef.current && post && post.title) {
        const titleText = titleRef.current.textContent;

        if (titleText.length > 29) {
          setIsTitleTooLong(true);
        } else {
          setIsTitleTooLong(false);
        }
      }
    };

    const observer = new MutationObserver(handleTitleChange);

    if (titleRef.current && post && post.title) {
      handleTitleChange(); // Vérifier la longueur du titre initialement
      observer.observe(titleRef.current, {
        characterData: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect(); // Arrêter l'observation lors du démontage du composant
    };
  }, [post]);

  useEffect(() => {
    ReactGA.initialize("G-GBDKKHN7RW");
    ReactGA.send("pageview", { page_path: window.location.pathname });
  }, [location]);

  // Permet de gérer l'affichage de la description du lieu.

  const handleLieuTitleClick = (lieuId) => {
    setSelectedLieuId(selectedLieuId === lieuId ? null : lieuId);

    // Récupérer l'image du lieu sélectionné
    const selectedLieu = postLieux.find((lieu) => lieu._id === lieuId);
    setSelectedLieuImage(
      selectedLieu
        ? lieuImage.filter((image) =>
            selectedLieu.image.includes(image._id.toString())
          )
        : []
    );
  };

  useEffect(() => {
    setShowLieuDesc(true);
  }, []);

  const { code } = useParams();
  const navigate = useNavigate();

  const handleNextClick = async () => {
    try {
      const response = await axios.get(`${serverUrl}/post/${code}`);
      const nextPost = response.data.post.next;
      setNextPost(nextPost); // Met à jour nextPost
      if (nextPost) {
        setIsAnswerCorrect(false);
        navigate(`/${nextPost}`);
        window.scrollTo(0, 0);
        setErrorCount(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDoubleNextClick = async (nextCode) => {
    try {
      setIsAnswerCorrect(false);
      navigate(`/${nextCode}`);
      window.scrollTo(0, 0);
      setErrorCount(0);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrevClick = async () => {
    try {
      const response = await axios.get(`${serverUrl}/post/${code}`);
      const prevPost = response.data.post.prev;
      setPrevPost(prevPost); // Met à jour prevPost
      if (prevPost) {
        setIsAnswerCorrect(false);
        navigate(`/${prevPost}`);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUserReponseSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(`${serverUrl}/enigme/${post.enigme}`);
      const enigmeAnswer = response.data.enigme.answer;

      if (userReponse === enigmeAnswer) {
        console.log("Réponse correcte!");
        setShowEnigmeText(true);
        setIsAnswerCorrect(true);
        setShowConfetti(true);
      } else {
        console.log("Réponse incorrecte!");
        setShowEnigmeText(false);
        setIsAnswerCorrect(false);

        setErrorCount(errorCount + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleIconClick = (event) => {
    event.preventDefault();
    setIsFlipped(true);

    setTimeout(() => {
      // Après une certaine durée, retour à l'état initial
      setIsFlipped(false);
    }, 1000); // Durée en millisecondes de l'animation
  };

  useEffect(() => {
    axios
      .get(`${serverUrl}/image/`)
      .then((response) => setImages(response.data.Images))
      .catch((error) => console.error(error));
    axios
      .get(`${serverUrl}/post/${code}`)
      .then((response) => {
        const postData = response.data.post;
        setPost(postData);
        setPrevPost(postData.prev); // Initialise prevPost
        setNextPost(postData.next); // Initialise nextPost
        setDoublenext(postData.doublenext); // Initialise doublenext
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(`${serverUrl}/enigme/`)
      .then((response) => setEnigmes(response.data.Enigmes))
      .catch((error) => console.error(error));

    axios
      .get(`${serverUrl}/lieu`)
      .then((response) => setLieux(response.data.Lieux))
      .catch((error) => console.error(error));
  }, [code, serverUrl]);

  if (!post || !lieux || !enigmes) {
    return <Loading />;
  }

  // Récupérer les ID des lieux dans le tableau de données du post en question.
  const postLieux = lieux.filter((place) =>
    post.lieu.includes(place._id.toString())
  );
  const postEnigmes = enigmes.filter((enigme) =>
    post.enigme.includes(enigme._id.toString())
  );
  const postImages = images.filter((image) =>
    post.image.includes(image._id.toString())
  );
  const enigmeImages =
    postEnigmes.length > 0 && postEnigmes[0].image
      ? images.filter((image) =>
          postEnigmes[0].image.includes(image._id.toString())
        )
      : [];
  const answerImages =
    postEnigmes.length > 0 && postEnigmes[0].imageanswer
      ? images.filter((image) =>
          postEnigmes[0].imageanswer.includes(image._id.toString())
        )
      : [];
  const lieuImage =
    postLieux.length > 0 && postLieux[0].image
      ? images.filter((image) =>
          postLieux[0].image.includes(image._id.toString())
        )
      : [];

  const handleGoogleMapsClick = (mapsUrl) => {
    window.open(mapsUrl, "_blank");
  };

  return (
    <>
      <Helmet>
        <title>{post.code}</title>
      </Helmet>
      <Navbar />
      <Header />
      <div className="post">
        <div className="post__sub">
          <h1
            ref={titleRef}
            className={`post__sub__title ${isTitleTooLong ? "break-line" : ""}`}
          >
            {post.title}
          </h1>
          <hr className="post__sub__barre" />
        </div>
        <div className="postTop">
          {postImages.length > 0 ? (
            postImages.map((image) => (
              <div className="post__container" key={image._id}>
                <div className="post__container__sub"></div>
                <div className="post__container__images">
                  <img
                    className="post__container__images__img"
                    src={image.url}
                    alt=""
                  />
                </div>
                <div className="post__container__under">
                  <div
                    className="post__container__under__desc"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {post.desc}
                  </div>
                  {post.maps && (
                    <button
                      className="post__container__under__maps"
                      onClick={() => handleGoogleMapsClick(post.maps)}
                    >
                      Ernest vous y emmène&nbsp;!
                    </button>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>Aucun Post trouvé</p>
          )}

          {postLieux.length > 0 ? (
            <div className="post__container__lieu">
              <div className="post__group">
                <p className="post__group__code">{post.code}</p>
                <hr className="post__group__barre" />
              </div>
              {postLieux.map((lieu) => {
                const lieuImage = lieu.image
                  ? images.filter((image) =>
                      lieu.image.includes(image._id.toString())
                    )
                  : [];
                return (
                  <div key={lieu._id}>
                    <button
                      onClick={() => handleLieuTitleClick(lieu._id)}
                      className="post__container__lieu__title"
                    >
                      <FontAwesomeIcon icon={faLocationDot} />
                      {lieu.title}
                    </button>
                    {selectedLieuId === lieu._id && (
                      <div
                        style={{ whiteSpace: "pre-wrap" }}
                        className={`post__container__lieu__desc ${
                          selectedLieuId === lieu._id ? "active" : ""
                        }`}
                      >
                        {lieu.desc}
                        {lieuImage.length > 0 ? (
                          lieuImage.map((image) => (
                            <div
                              className="post__container__lieu__desc__images"
                              key={image._id}
                            >
                              <img
                                className="post__container__lieu__desc__images__img"
                                src={image.url}
                                alt=""
                              />
                            </div>
                          ))
                        ) : (
                          <p></p>
                        )}
                        {lieu.maps && (
                          <button
                            className="post__container__lieu__maps"
                            onClick={() => handleGoogleMapsClick(lieu.maps)}
                          >
                            Ouvrir dans Google Maps
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <p>Aucun lieu trouvé</p>
          )}
        </div>
        {postEnigmes.length > 0 ? (
          postEnigmes.map((enigme) => (
            <div className="post__container__enigme" key={enigme._id}>
              <div className="post__container__enigme__sub">
                <h1 className="post__container__enigme__sub__title">
                  {enigme.title}
                </h1>
                <hr className="post__container__enigme__sub__barre" />
              </div>
              <div
                style={{ whiteSpace: "pre-wrap" }}
                className="post__container__enigme__desc"
              >
                {enigme.desc}
              </div>
              {enigmeImages.length > 0 ? (
                enigmeImages.map((image) => (
                  <div
                    className="post__container__enigme__images"
                    key={image._id}
                  >
                    <img
                      className="post__container__enigme__images__imageText"
                      src={image.url}
                    ></img>
                  </div>
                ))
              ) : (
                <p></p>
              )}
              {errorCount >= 3 && (
                <div
                  style={{ whiteSpace: "pre-wrap" }}
                  className="post__container__enigme__desc glow"
                >
                  Indice : {enigme.indice}
                </div>
              )}
              {isAnswerCorrect ? (
                <>
                  {showConfetti && (
                    <ConfettiCanvas fadingMode="LIGHT" stopAfterMs={5000} />
                  )}
                  <p className="post__container__enigme__container__reponse glow">
                    <strong>La réponse était bien : {enigme.answer}</strong>
                  </p>
                  <div
                    style={{ whiteSpace: "pre-wrap" }}
                    className="post__container__enigme__container__reponse"
                  >
                    {enigme.text}
                  </div>
                  <p className="post__container__enigme__container__reponse__clef glow">
                    Clef :
                  </p>
                  {answerImages.length > 0 ? (
                    answerImages.map((image) => (
                      <div
                        className="post__container__enigme__images"
                        key={image._id}
                      >
                        <img
                          className="post__container__enigme__images__imageText"
                          src={image.url}
                        ></img>
                      </div>
                    ))
                  ) : (
                    <p></p>
                  )}
                </>
              ) : (
                <div className="post__container__enigme__container">
                  <form
                    onSubmit={handleUserReponseSubmit}
                    className="post__container__enigme__container__form"
                  >
                    <input
                      id="reponseInput"
                      name="reponseInput"
                      value={userReponse}
                      onChange={(event) =>
                        setUserReponse(event.target.value.toLowerCase())
                      }
                      className="post__container__enigme__container__form__input"
                      type="text"
                      placeholder="Votre réponse"
                    />
                    <button
                      onClick={handleUserReponseSubmit}
                      type="submit"
                      className="post__container__enigme__container__form__button"
                    >
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                        className={`post__container__enigme__container__form__button__icon ${
                          isFlipped ? "fa-flip" : ""
                        }`}
                        onClick={handleIconClick}
                      />
                    </button>
                  </form>
                </div>
              )}
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>
      <div className="post__container__under__button">
        {prevPost && (
          <button
            className="post__container__under__button__next"
            onClick={handlePrevClick}
          >
            Précédent
          </button>
        )}
        {doublenext ? (
          <>
          <div className="doubleNext">
            <p> Chemins possibles :</p>
            <button
              className="post__container__under__button__next"
              onClick={() => handleDoubleNextClick(nextPost)}
            >
              Premier
            </button>
            <button
              className="post__container__under__button__next"
              onClick={() => handleDoubleNextClick(doublenext)}
            >
              Deuxième
            </button>
          </div>
          </>
        ) : (
          nextPost && (
            <button
              className="post__container__under__button__next"
              onClick={handleNextClick}
            >
              Suivant
            </button>
          )
        )}
      </div>
    </>
  );
}
