import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Dice from './Dice'; 

export default function Informations(props) {
    const [isLogged, setIsLogged] = useState(Boolean(sessionStorage.getItem('accessToken')) || false);
    const [lieu, setLieu] = useState(sessionStorage.getItem('lieu') || 'Lille Centre');
    const [time, setTime] = useState(sessionStorage.getItem('time') || 'De 1h à 1/2 journée pour +/- 8km');
    const [indice, setIndice] = useState(sessionStorage.getItem('indice') || `Chaque étape du parcours 02 comporte une énigme qui permet d'avancer dans l'aventure. Si vous ne parvenez pas à trouver la réponse au bout de 3 essais, un indice apparaitra alors juste au dessus. Pour être certain de ne rien rater de votre périple, n'hésitez pas à suivre Tales & Keys sur : `);

    const handleContentEditableLocation = (event) => {
        const newValue = event.target.innerText;
        setLieu(newValue);
        sessionStorage.setItem('lieu', newValue);
    };

    const handleContentEditableTime = (event) => {
        const newValue = event.target.innerText;
        setTime(newValue);
        sessionStorage.setItem('time', newValue);
    };

    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" />
            </Helmet>
            <div className='info'>
                <Dice />    
            </div>
        </>
    );
}
