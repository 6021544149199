import React from 'react'
export default function Loading() {
    return (
        <>
            <div>
                <div>
                    <h1 className='header__title'>Sheep</h1>
                    <br />
                    <h2 className='header__subtitle'>Circus</h2>
                </div>
                <div className='loadingContainer'>
                    <div className='loadingContainer__clef'></div>
                    <p className='loadingContainer__loading'>Chargement...</p>
                    <p className='loadingContainer__subloading'>Merci de votre attente !</p>
                </div>
            </div>
        </>
    )
}
